import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createZtoAsyncExhaustCtx } from '../../libs/zto-redux/zto-redux';
import { createSelector, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

export const notificationsSelector = 'notifications';

export type NotificationType = 'REMINDER';

export interface NotificationPayload {
    visitId: string;
    type: NotificationType;
    message: string;
}
export interface Notification {
    id: string;
    _id: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
}

export const NotificationsActionsTypes = {
    GET_ALL: '[NOTIFICATIONS] GET -- ALL',
    SEND: '[NOTIFICATIONS] SEND',
};

export const notificationsGetAllCtx = createZtoAsyncExhaustCtx(`${notificationsSelector}GetAll`, NotificationsActionsTypes.GET_ALL)<[], Notification[]>();
export const notificationsSendCtx = createZtoAsyncExhaustCtx(`${notificationsSelector}Send`, NotificationsActionsTypes.SEND)<[string], {}>();

export interface NotificationsState {
    loading: boolean;
    fetched: boolean;
    notifications: EntityState<Notification>;
    error: { name: string, message: string };
    getAllCache: typeof notificationsGetAllCtx['defaultState'];
    sendCache: typeof notificationsSendCtx['defaultState'];
    hasSendSomething: boolean;
    lastSendOk?: boolean;
}

const notificationsAdapter = createEntityAdapter<Notification>();

export const initialNotificationsState: NotificationsState = {
    loading: false,
    fetched: false,
    notifications: notificationsAdapter.getInitialState(),
    error: undefined,
    hasSendSomething: false,
    getAllCache: notificationsGetAllCtx.defaultState,
    sendCache: notificationsSendCtx.defaultState,
}

export type NotificationsActions = typeof notificationsGetAllCtx['Actions'][keyof typeof notificationsGetAllCtx['Actions']]['prototype']
    | typeof notificationsSendCtx['Actions'][keyof typeof notificationsSendCtx['Actions']]['prototype'];

export function notificationsReducer(state: NotificationsState = initialNotificationsState, action: NotificationsActions): NotificationsState {
    const isLoading = (st: NotificationsState) => st.getAllCache.loading || st.sendCache.loading;
    const isError = (st: NotificationsState) => st.getAllCache.error || st.sendCache.error;
    switch (action.type) {

        case notificationsGetAllCtx.Types.REQUEST: {
            const getAllCache = notificationsGetAllCtx.reducer(state.getAllCache, action as any);
            const newState = { ...state, getAllCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case notificationsGetAllCtx.Types.CANCEL: {
            const getAllCache = notificationsGetAllCtx.reducer(state.getAllCache, action as any);
            const newState = { ...state, getAllCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case notificationsGetAllCtx.Types.RESOLVE: {
            const getAllCache = notificationsGetAllCtx.reducer(state.getAllCache, action as any);
            const newState = { ...state, getAllCache };
            return {
                ...newState,
                loading: isLoading(newState),
                fetched: true,
                notifications: notificationsAdapter.addAll(action.payload, { ...state.notifications })
            };
        }
        case notificationsGetAllCtx.Types.REJECT: {
            const getAllCache = notificationsGetAllCtx.reducer(state.getAllCache, action as any);
            const newState = { ...state, getAllCache };
            return {
                ...newState,
                loading: isLoading(newState),
                error: action.payload
            };
        }
        case notificationsGetAllCtx.Types.RESET: {
            const getAllCache = notificationsGetAllCtx.reducer(state.getAllCache, action as any);
            const newState = { ...state, getAllCache };
            return {
                ...newState,
                loading: isLoading(newState),
                notifications: notificationsAdapter.removeAll({ ...state.notifications }),
                fetched: false
            };
        }
        case notificationsGetAllCtx.Types.CLEAR_ERRORS: {
            const getAllCache = notificationsGetAllCtx.reducer(state.getAllCache, action as any);
            const newState = { ...state, getAllCache };
            return {
                ...newState,
                loading: isLoading(newState),
                error: isError(newState)
            };
        }


        case notificationsSendCtx.Types.REQUEST: {
            const sendCache = notificationsSendCtx.reducer(state.sendCache, action as any);
            const newState = { ...state, sendCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case notificationsSendCtx.Types.CANCEL: {
            const sendCache = notificationsSendCtx.reducer(state.sendCache, action as any);
            const newState = { ...state, sendCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case notificationsSendCtx.Types.RESOLVE: {
            const sendCache = notificationsSendCtx.reducer(state.sendCache, action as any);
            const newState = { ...state, sendCache };
            return {
                ...newState,
                loading: isLoading(newState),
                lastSendOk: true,
                hasSendSomething: true,
            };
        }
        case notificationsSendCtx.Types.REJECT: {
            const sendCache = notificationsSendCtx.reducer(state.sendCache, action as any);
            const newState = { ...state, sendCache };
            return {
                ...newState,
                loading: isLoading(newState),
                error: action.payload,
                lastSendOk: false,
                hasSendSomething: true,
            };
        }
        case notificationsSendCtx.Types.RESET: {
            const sendCache = notificationsSendCtx.reducer(state.sendCache, action as any);
            const newState = { ...state, sendCache };
            return {
                ...newState,
                loading: isLoading(newState)
            };
        }
        case notificationsSendCtx.Types.CLEAR_ERRORS: {
            const sendCache = notificationsSendCtx.reducer(state.sendCache, action as any);
            const newState = { ...state, sendCache };
            return {
                ...newState,
                loading: isLoading(newState),
                error: isError(newState)
            };
        }

        default:
            return state;
    }
}

export const notificationsSelectStateFn = (state: any) => state[notificationsSelector] as NotificationsState;

export const notificationsSelectorFns = {
    state: notificationsSelectStateFn,
    loading: createSelector(notificationsSelectStateFn, state => state.loading),
    fetched: createSelector(notificationsSelectStateFn, state => state.fetched),
    hasSendSomething: createSelector(notificationsSelectStateFn, state => state.hasSendSomething),
    lastSendOk: createSelector(notificationsSelectStateFn, state => state.lastSendOk),
    notifications: createSelector(notificationsSelectStateFn, state => state.notifications),
    notificationsEntities: createSelector(notificationsSelectStateFn, state => state.notifications.entities),
    notificationsIds: createSelector(notificationsSelectStateFn, state => state.notifications.ids),
    error: createSelector(notificationsSelectStateFn, state => state.error),
    getAllCache: createSelector(notificationsSelectStateFn, state => state.getAllCache),
    sendCache: createSelector(notificationsSelectStateFn, state => state.sendCache),
};

export const createNotificationsSelectors = (store: Store<any>) => ({
    state: store.pipe(map(notificationsSelectorFns.state)),
    loading: store.pipe(map(notificationsSelectorFns.loading)),
    fetched: store.pipe(map(notificationsSelectorFns.fetched)),
    hasSendSomething: store.pipe(map(notificationsSelectorFns.hasSendSomething)),
    lastSendOk: store.pipe(map(notificationsSelectorFns.lastSendOk)),
    notifications: store.pipe(map(notificationsSelectorFns.notifications)),
    notificationsEntities: store.pipe(map(notificationsSelectorFns.notificationsEntities)),
    notificationsIds: store.pipe(map(notificationsSelectorFns.notificationsIds)),
    error: store.pipe(map(notificationsSelectorFns.error)),
    getAllCache: store.pipe(map(notificationsSelectorFns.getAllCache)),
    getOneCache: store.pipe(map(notificationsSelectorFns.sendCache)),
});
