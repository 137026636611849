import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogConfigData } from 'src/app/models/confirmation-dialog-config-data';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

 
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogConfigData
  ) {}

  ngOnInit() {
  }

  negative() {
    this.data.negativeAnswer.handler();
    this.onNoClick();
  }

  positive() {
    this.data.positiveAnswer.handler();
    this.onNoClick();
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }
}
