import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Credentials } from 'src/app/redux/auth/state';
import { environment } from 'src/environments/environment';
import { map, switchMap, delay } from 'rxjs/operators';
import { throwError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient
  ) { }

  signup(credentials: Credentials) {
    return this.http.post(`${environment.baseApi}/auth/signup`, credentials).pipe(map(() => credentials));
  }

  signin(credentials: Credentials) {
    return this.http.post<any>(`${environment.baseApi}/auth/login`, credentials).pipe(
      switchMap(resp => {
        if (resp && resp.status === 401) {
          return throwError(new Error('401'));
        }
        return of(resp);
      }),
      // delay(1000),
      switchMap((resp: any) => {
        const user = resp && resp.user;
        if (user && user.token) {
          return of({ token: user.token, user: user });
        } else {
          return throwError(new Error('401'));
        }
      })
    );
  }

  signout() {
    return this.http.post(`${environment.baseApi}/auth/logout`, {});
  }

}
