import { ZtoAction } from 'src/app/libs/zto-redux/zto-redux';

export interface Link {
    translateKey: string;
    link: string;
    params?: any;
}

export interface AppState {
    loading: number;
    error?: { name: string, message: string };
    links: Link[]
}

export const appSelector = 'app';

export const initialAppState: AppState = {
    loading: 0,
    links: [
        {
            translateKey: 'menu.signup',
            link: '/sign',
            params: { view: 'signup' }
        },
        {
            translateKey: 'menu.signin',
            link: '/sign',
            params: { view: 'signin' }
        },
    ]
}

export const AppActionsTypes = {
    LOAD_START: '[APP] LOAD -- START',
    LOAD_STOP: '[APP] LOAD -- STOP',
    SET_ERROR: '[APP] ERROR -- SET',
    CHANGE_LINKS: '[APP] LINKS -- CHANGE',
};

export class AppLoadStart extends ZtoAction<typeof AppActionsTypes.LOAD_START, void> {
    constructor() {
        super(AppActionsTypes.LOAD_START, undefined);
    }
}

export class AppLoadStop extends ZtoAction<typeof AppActionsTypes.LOAD_STOP, void> {
    constructor() {
        super(AppActionsTypes.LOAD_STOP, undefined);
    }
}

export class AppSetError extends ZtoAction<typeof AppActionsTypes.SET_ERROR, { name: string, message: string }> {
    constructor(
        payload: { name: string, message: string }
    ) {
        super(AppActionsTypes.SET_ERROR, payload);
    }
}

export class AppChangeLinks extends ZtoAction<typeof AppActionsTypes.CHANGE_LINKS, Link[]> {
    constructor(
        payload: Link[]
    ) {
        super(AppActionsTypes.CHANGE_LINKS, payload);
    }
}

export type AppActions = AppLoadStart | AppLoadStop | AppSetError | AppChangeLinks;

export function appReducer(state: AppState = initialAppState, action: AppActions): AppState {
    switch (action.type) {
        case AppActionsTypes.LOAD_START:
            return {
                ...state,
                loading: state.loading + 1
            };
        case AppActionsTypes.LOAD_STOP:
            return {
                ...state,
                loading: state.loading - 1
            };
        case AppActionsTypes.SET_ERROR:
            return {
                ...state,
                error: (action as AppSetError).payload
            };
        case AppActionsTypes.CHANGE_LINKS:
            return {
                ...state,
                links: (action as AppChangeLinks).payload
            };
        default:
            return state;
    }
}