import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Fid } from 'src/app/redux/fids/state';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FidsService {

  constructor(
    private http: HttpClient
  ) { }

  getAll() {
    return this.http.get<Fid[]>(`${environment.baseApi}/v1/advantages`).pipe(
      map(fids => fids.map(fid => ({ ...fid, id: fid._id })))
    );
  }

  getOne(id: string) {
    return this.http.get<Fid>(`${environment.baseApi}/v1/advantages/${id}`).pipe(
      map(fid => ({ ...fid, id: fid._id }))
    );
  }

  createOne(fid: Partial<Fid>) {
    return this.http.post<Fid>(`${environment.baseApi}/v1/advantages`, fid).pipe(
      map(fid => ({ ...fid, id: fid._id }))
    );
  }

  updateOne(id: string, changes: Partial<Fid>) {
    return this.http.put<Fid>(`${environment.baseApi}/v1/advantages/${id}`, { id, changes }).pipe(
      map(fid => ({ ...fid, id: fid._id }))
    );
  }

  deleteOne(id: string) {
    return this.http.delete<{ id: string }>(`${environment.baseApi}/v1/advantages/${id}`).pipe(map(() => ({ id })));
  }
}
