import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { AuthFacade } from '../auth/facade';
import { distinctUntilChanged, map, skip, delayWhen, distinctUntilKeyChanged, filter, tap, concatMap, exhaustMap } from 'rxjs/operators';
import { AppChangeLinks, AppLoadStart, AppLoadStop, AppActionsTypes, AppSetError } from './state';
import { merge, timer, BehaviorSubject } from 'rxjs';
import { FidsFacade } from '../fids/facade';
import { DialogsService } from 'src/app/services/dialogs/dialogs.service';
import { AppFacade } from './facade';
import { authAutoSigninCtx, authSignupCtx, authSigninCtx, authSignoutCtx } from '../auth/state';
import { fidsGetAllCtx, fidsGetOneCtx, fidsCreateOneCtx, fidsUpdateOneCtx, fidsDeleteOneCtx } from '../fids/state';
import { NotificationsFacade } from '../notifications/facade';
import { notificationsGetAllCtx, notificationsSendCtx } from '../notifications/state';

@Injectable()
export class AppEffects {

    @Effect()
    changeMenu$ = this.auth.select.authenticated.pipe(
        distinctUntilChanged(),
        map(authenticated => new AppChangeLinks(
            authenticated
                ? [
                    {
                        translateKey: 'menu.dashboard',
                        link: '/dashboard',
                    },
                    {
                        translateKey: 'menu.fids',
                        link: '/fids',
                        params: { mode: 'list' }
                    },
                    {
                        translateKey: 'menu.notifications',
                        link: '/notifications',
                        params: { mode: 'list' }
                    },
                    /*
                    {
                        translateKey: 'menu.infos',
                        link: '/infos',
                    },
                    */
                    {
                        translateKey: 'menu.signout',
                        link: 'sign',
                        params: { view: 'signout' }
                    },
                ]
                : [
                    {
                        translateKey: 'menu.signin',
                        link: 'sign',
                        params: { view: 'signin' }
                    },
                    /*
                    {
                        translateKey: 'menu.infos',
                        link: '/infos',
                    },
                    */
                    /*
                    {
                        translateKey: 'menu.signup',
                        link: 'sign',
                        params: { view: 'signup' }
                    }
                    */
                ]
        ))
    );

    @Effect()
    mergeLoadings$ = merge(
        this.auth.select.loading.pipe(distinctUntilChanged(), skip(1)),
        this.fids.select.loading.pipe(distinctUntilChanged(), skip(1)),
        this.notifications.select.loading.pipe(distinctUntilChanged(), skip(1)),
    ).pipe(
        distinctUntilChanged(),
        map(loading => loading ? new AppLoadStart : new AppLoadStop),
    );

    @Effect()
    mergeErrors$ = merge(
        this.auth.select.autoSigninCache.pipe(map(cache => cache.error, filter(error => !!error))),
        this.auth.select.signupCache.pipe(map(cache => cache.error, filter(error => !!error))),
        this.auth.select.signinCache.pipe(map(cache => cache.error, filter(error => !!error))),
        this.auth.select.signoutCache.pipe(map(cache => cache.error, filter(error => !!error))),
        this.fids.select.error.pipe(filter(error => !!error)),
        this.notifications.select.error.pipe(filter(error => !!error)),
    ).pipe(
        filter(error => !!error),
        exhaustMap(error => {
            const ref = this.dialog.openReportErrorDialog({ data: { error } });
            return ref.afterClosed().pipe(concatMap(() => [
                new authAutoSigninCtx.Actions.ClearErrors(),
                new authSignupCtx.Actions.ClearErrors(),
                new authSigninCtx.Actions.ClearErrors(),
                new authSignoutCtx.Actions.ClearErrors(),
                new fidsGetAllCtx.Actions.ClearErrors(),
                new fidsGetOneCtx.Actions.ClearErrors(),
                new fidsCreateOneCtx.Actions.ClearErrors(),
                new fidsUpdateOneCtx.Actions.ClearErrors(),
                new fidsDeleteOneCtx.Actions.ClearErrors(),
                new notificationsGetAllCtx.Actions.ClearErrors(),
                new notificationsSendCtx.Actions.ClearErrors(),
            ]));
        })
    );

    delayLoadings = new BehaviorSubject(0);

    constructor(
        public actions$: Actions,
        public auth: AuthFacade,
        public app: AppFacade,
        public fids: FidsFacade,
        public notifications: NotificationsFacade,
        public dialog: DialogsService
    ) {}
}

