import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: 'infos',
    loadChildren: () => import('./pages/infos-page/infos-page.module').then(m => m.InfosPageModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard-page/dashboard-page.module').then(m => m.DashboardPageModule),
  },
  {
    path: 'fids',
    loadChildren: () => import('./pages/fids-page/fids-page.module').then(m => m.FidsPageModule),
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications-page/notifications-page.module').then(m => m.NotificationsPageModule),
  },
  {
    path: 'sign',
    loadChildren: () => import('./pages/sign-page/sign-page.module').then(m => m.SignPageModule),
  },
  {
    path: '',
    redirectTo: '/sign?view=signin',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/sign?view=signin',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
