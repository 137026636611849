import { v4 as uuid } from 'uuid';
import { Store, createSelector } from '@ngrx/store';

import { map, exhaustMap, take, takeUntil, catchError, switchMap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

export class ZtoAction<T extends string = string, P = any> {
    constructor(
        public type: T,
        public payload: P,
        public id: string = uuid()
    ) {}
}

export function createZtoAsyncExhaustCtx<S extends string, T extends string>(selector: S, type: T) {
    return <P extends Array<any> = Array<any>, R = any, I extends { serviceFn: (...params: P) => Observable<R> } = any>(
        defaultParam?: P,
        resultForType?: R
    ) => {
        const Types = {
            REQUEST: `${type} -- ASYNC -- REQUEST`,
            CANCEL: `${type} -- ASYNC -- CANCEL`,
            RESOLVE: `${type} -- ASYNC -- RESOLVE`,
            REJECT: `${type} -- ASYNC -- REJECT`,
            RESET: `${type} -- RESET`,
            CLEAR_ERRORS: `${type} -- CLEAR ERRORS`,
        };
        interface State {
            loading: boolean;
            initiated: boolean;
            params?: P;
            result?: R;
            error?: { name: string, message: string };
        };
        class Request extends ZtoAction<string, P> {
            constructor(
                public payload: P,
                id?: string
            ) {
                super(Types.REQUEST, payload, id);
            }
        };
        class Cancel extends ZtoAction<string, void> {
            constructor(
                id?: string
            ) {
                super(Types.CANCEL, undefined, id);
            }
        };
        class Resolve extends ZtoAction<string, R> {
            constructor(
                public payload: R,
                id?: string
            ) {
                super(Types.RESOLVE, payload, id);
            }
        };
        class Reject extends ZtoAction<string, { name: string, message: string }> {
            constructor(
                public payload: { name: string, message: string },
                id?: string
            ) {
                super(Types.REJECT, payload, id);
            }
        };
        class Reset extends ZtoAction<string, void> {
            constructor(
                id?: string
            ) {
                super(Types.RESET, undefined, id);
            }
        };
        class ClearErrors extends ZtoAction<string, void> {
            constructor(
                id?: string
            ) {
                super(Types.CLEAR_ERRORS, undefined, id);
            }
        };
        type ThisActions = Request | Cancel | Resolve | Reject | Reset | ClearErrors;
        const defaultState: State = {
            loading: false,
            initiated: false
        };
        const reducer = (state: State = defaultState, action: ThisActions) => {
            switch (action.type) {
                case Types.REQUEST:
                    return {
                        ...state,
                        loading: true,
                        params: (action as Request).payload
                    };
                case Types.CANCEL:
                    return {
                        ...state,
                        loading: false,
                    };
                case Types.RESOLVE:
                    return {
                        ...state,
                        loading: false,
                        initiated: true,
                        result: (action as Resolve).payload
                    };
                case Types.REJECT:
                    return {
                        ...state,
                        loading: false,
                        error: (action as Reject).payload
                    };
                case Types.RESET:
                    return {
                        ...state,
                        ...defaultState
                    };
                case Types.CLEAR_ERRORS:
                    return {
                        ...state,
                        error: undefined
                    };
                default:
                    return state;
            }
        };
        const selectState = (state: any) => state[selector] as State;
        const selectors = {
            state: (store: Store<any>) => store.pipe(map(selectState)),
            loading: (store: Store<any>) => store.pipe(map(createSelector(selectState, state => state.loading))),
            initiated: (store: Store<any>) => store.pipe(map(createSelector(selectState, state => state.initiated))),
            params: (store: Store<any>) => store.pipe(map(createSelector(selectState, state => state.params))),
            result: (store: Store<any>) => store.pipe(map(createSelector(selectState, state => state.result))),
            error: (store: Store<any>) => store.pipe(map(createSelector(selectState, state => state.error))),
        };
        const computedSelectors = {
            hasParam: (store: Store<any>) => selectors.params(store).pipe(map(params => !!params)),
            hasResult: (store: Store<any>) => selectors.result(store).pipe(map(result => !!result)),
            hasError: (store: Store<any>) => selectors.error(store).pipe(map(error => !!error)),
        };
        const effect = (injector: I, actions: Actions, store: Store<any>) => actions.pipe(
            ofType(Types.REQUEST),
            exhaustMap((req: Request) => injector.serviceFn(...req.payload).pipe(
                take(1),
                takeUntil(actions.pipe(ofType(Types.CANCEL))),
                map(result => new Resolve(result)),
                catchError(error => of(new Reject({ name: error && error.name, message: error && error.message })))
            ))
        );
        return {
            selectors: { ...selectors, ...computedSelectors },
            Types,
            Actions: { Request, Cancel, Resolve, Reject, Reset, ClearErrors },
            reducer,
            effect,
            defaultState
        };
    };
}

export function createZtoAsyncSwitchCtx<S extends string, T extends string>(selector: S, type: T) {
    return <P extends Array<any> = Array<any>, R = any, I extends { serviceFn: (...params: P) => Observable<R> } = any>(
        defaultParam?: P,
        resultForType?: R
    ) => {
        const Types = {
            REQUEST: `${type} -- ASYNC -- REQUEST`,
            CANCEL: `${type} -- ASYNC -- CANCEL`,
            RESOLVE: `${type} -- ASYNC -- RESOLVE`,
            REJECT: `${type} -- ASYNC -- REJECT`,
            RESET: `${type} -- RESET`,
            CLEAR_ERRORS: `${type} -- CLEAR ERRORS`,
        };
        interface State {
            loading: boolean;
            initiated: boolean;
            params?: P;
            result?: R;
            error?: { name: string, message: string };
        };
        class Request extends ZtoAction<string, P> {
            constructor(
                public payload: P,
                id?: string
            ) {
                super(Types.REQUEST, payload, id);
            }
        };
        class Cancel extends ZtoAction<string, void> {
            constructor(
                id?: string
            ) {
                super(Types.CANCEL, undefined, id);
            }
        };
        class Resolve extends ZtoAction<string, R> {
            constructor(
                public payload: R,
                id?: string
            ) {
                super(Types.RESOLVE, payload, id);
            }
        };
        class Reject extends ZtoAction<string, { name: string, message: string }> {
            constructor(
                public payload: { name: string, message: string },
                id?: string
            ) {
                super(Types.REJECT, payload, id);
            }
        };
        class Reset extends ZtoAction<string, void> {
            constructor(
                id?: string
            ) {
                super(Types.RESET, undefined, id);
            }
        };
        class ClearErrors extends ZtoAction<string, void> {
            constructor(
                id?: string
            ) {
                super(Types.CLEAR_ERRORS, undefined, id);
            }
        };
        type ThisActions = Request | Cancel | Resolve | Reject | Reset | ClearErrors;
        const defaultState: State = {
            loading: false,
            initiated: false
        };
        const reducer = (state: State = defaultState, action: ThisActions) => {
            switch (action.type) {
                case Types.REQUEST:
                    return {
                        ...state,
                        loading: true,
                        params: (action as Request).payload
                    };
                case Types.CANCEL:
                    return {
                        ...state,
                        loading: false,
                    };
                case Types.RESOLVE:
                    return {
                        ...state,
                        loading: false,
                        initiated: true,
                        result: (action as Resolve).payload
                    };
                case Types.REJECT:
                    return {
                        ...state,
                        loading: false,
                        error: (action as Reject).payload
                    };
                case Types.RESET:
                    return {
                        ...state,
                        ...defaultState
                    };
                case Types.CLEAR_ERRORS:
                    return {
                        ...state,
                        error: undefined
                    };
                default:
                    return state;
            }
        };
        const selectState = (state: any) => state[selector] as State;
        const selectors = {
            state: (store: Store<any>) => store.pipe(map(selectState)),
            loading: (store: Store<any>) => store.pipe(map(createSelector(selectState, state => state.loading))),
            initiated: (store: Store<any>) => store.pipe(map(createSelector(selectState, state => state.initiated))),
            params: (store: Store<any>) => store.pipe(map(createSelector(selectState, state => state.params))),
            result: (store: Store<any>) => store.pipe(map(createSelector(selectState, state => state.result))),
            error: (store: Store<any>) => store.pipe(map(createSelector(selectState, state => state.error))),
        };
        const computedSelectors = {
            hasParam: (store: Store<any>) => selectors.params(store).pipe(map(params => !!params)),
            hasResult: (store: Store<any>) => selectors.result(store).pipe(map(result => !!result)),
            hasError: (store: Store<any>) => selectors.error(store).pipe(map(error => !!error)),
        };
        const effect = (injector: I, actions: Actions, store: Store<any>) => actions.pipe(
            ofType(Types.REQUEST),
            switchMap((req: Request) => injector.serviceFn(...req.payload).pipe(
                take(1),
                takeUntil(actions.pipe(ofType(Types.CANCEL))),
                map(result => new Resolve(result)),
                catchError(error => of(new Reject({ name: error && error.name, message: error && error.message })))
            ))
        );
        return {
            selectors: { ...selectors, ...computedSelectors },
            Types,
            Actions: { Request, Cancel, Resolve, Reject, Reset, ClearErrors },
            reducer,
            effect,
            defaultState
        };
    };
}
