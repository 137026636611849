import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { ReportErrorDialogComponent } from './dialogs/report-error-dialog/report-error-dialog.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [HeaderComponent, ReportErrorDialogComponent, ConfirmationDialogComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    SharedModule
  ],
  exports: [
    HeaderComponent,
    ReportErrorDialogComponent,
    ConfirmationDialogComponent
  ],
  entryComponents: [
    ReportErrorDialogComponent,
    ConfirmationDialogComponent
  ]
})
export class ComponentsModule { }
