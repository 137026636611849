import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { AuthFacade } from 'src/app/redux/auth/facade';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() toggleMenuEvt: EventEmitter<void> = new EventEmitter();
  @Output() backEvt: EventEmitter<{ link: string, params?: any }> = new EventEmitter();
  @Output() gotoDashboardEvt: EventEmitter<void> = new EventEmitter();
  @Output() gotoInfosEvt: EventEmitter<void> = new EventEmitter();
  @Output() gotoNotifsEvt: EventEmitter<void> = new EventEmitter();

  backBtn: { hidden: boolean, link?: string, params?: any };

  authenticated$ = this.auth.select.authenticated;

  title: string = environment.appName;

  constructor(
    private nav: NavigationService,
    private auth: AuthFacade,
  ) {
    this.nav.backBtn.subscribe(backBtn => {
      this.backBtn = backBtn;
    });
  }

  ngOnInit() {
    this.nav.backBtn.subscribe()
  }

  toggleMenu() {
    this.toggleMenuEvt.emit();
  }

  back(link: string, params?: any) {
    this.backEvt.emit({ link, params });
  }

  gotoDashboard() {
    this.gotoDashboardEvt.emit();
  }

  gotoInfos() {
    this.gotoInfosEvt.emit();
  }

  gotoNotifs() {
    this.gotoNotifsEvt.emit();
  }

}
