import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ReportErrorDialogComponent } from 'src/app/components/dialogs/report-error-dialog/report-error-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ReportErrorDialogConfigData } from 'src/app/models/report-error-dialog-config-data';
import { ConfirmationDialogConfigData } from 'src/app/models/confirmation-dialog-config-data';

@Injectable({
  providedIn: 'root'
})
export class DialogsService {

  reportErrorDialogConfig: MatDialogConfig<ReportErrorDialogConfigData> = new MatDialogConfig<ReportErrorDialogConfigData>();
  confirmationDialogConfig: MatDialogConfig<ConfirmationDialogConfigData> = new MatDialogConfig<ConfirmationDialogConfigData>();

  constructor(
    private dialog: MatDialog
  ) { }

  openReportErrorDialog(
    config: MatDialogConfig<ReportErrorDialogConfigData>
  ) {
    const ref: MatDialogRef<ReportErrorDialogComponent> = this.dialog.open(
      ReportErrorDialogComponent,
      {
        ...this.reportErrorDialogConfig,
        ...config
      }
    );
    return ref;
  }

  openConfirmationDialog(
    config: MatDialogConfig<ConfirmationDialogConfigData>
  ) {
    const ref: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(
      ConfirmationDialogComponent,
      {
        ...this.confirmationDialogConfig,
        ...config
      }
    );
    return ref;
  }
}
