import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { notificationsGetAllCtx, createNotificationsSelectors, Notification, notificationsSendCtx } from './state';

@Injectable()
export class NotificationsFacade {

    select = createNotificationsSelectors(this.store);

    constructor(
        private store: Store<any>
    ) {}

    getAll() {
        this.store.dispatch(new notificationsGetAllCtx.Actions.Request([]));
    }

    send(visitId: string) {
        this.store.dispatch(new notificationsSendCtx.Actions.Request([visitId]));
    }
}