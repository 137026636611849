import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthFacade } from 'src/app/redux/auth/facade';
import { take, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private auth: AuthFacade
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token$ = this.auth.select.token;
    const mapHeaders = (token: string) => {
      let newHeaders = req.headers;
      if (token && req.url.includes(environment.baseApi)) {
         newHeaders = newHeaders.append('authorization', `Bearer ${token}`);
      }
      return newHeaders;
    };
    const mapParams = () => {
      let newParams = req.params;
      if (req.url.includes(environment.baseApi)) {
         // newParams = newParams.append('dev-api-key', environment.devApiKey);
      }
      return newParams;
    };
    const mapReq = (token: string) => req.clone({
      headers: mapHeaders(token),
      params: mapParams()
    });
    return token$.pipe(
      take(1),
      map(mapReq),
      switchMap(authReq => next.handle(authReq))
    );
   }

}