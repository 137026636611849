import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { AppLoadStart, AppSetError, Link, AppChangeLinks, appSelector, AppState, AppLoadStop } from './state';
import { map } from 'rxjs/operators';

@Injectable()
export class AppFacade {

    state$ = this.store.pipe(map(state => (state[appSelector] as AppState)));
    loading$ = this.store.pipe(map(state => (state[appSelector] as AppState).loading));
    isLoading$ = this.store.pipe(map(state => (state[appSelector] as AppState).loading > 0));
    error$ = this.store.pipe(map(state => (state[appSelector] as AppState).error));
    links$ = this.store.pipe(map(state => (state[appSelector] as AppState).links));

    constructor(
        public store: Store<any>
    ) {}

    loadStart() {
        this.store.dispatch(new AppLoadStart);
    }

    loadStop() {
        this.store.dispatch(new AppLoadStop);
    }

    setError(error: Error) {
        this.store.dispatch(new AppSetError(error));
    }

    changeLinks(links: Link[]) {
        this.store.dispatch(new AppChangeLinks(links));
    }

}