import { ZtoAction, createZtoAsyncExhaustCtx } from 'src/app/libs/zto-redux/zto-redux';
import { createSelector, Store } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { map } from 'rxjs/operators';

export class BasicBody {
    constructor(
        public basicContent: string,
        public basicSubContent: string = '',
    ) {}
}

export class RichBody {
    constructor(
        public richContent: string,
        public richSubContent: string[] = [],
        public richBtn: string = '',
    ) {}
}

export class Fid {
    id = this._id;
    constructor(
        public _id: string,
        public name: string,
        public preset: 'basic' | 'rich',
        public body: typeof preset extends 'basic' ? BasicBody : RichBody,
    ) {}
}

export const fidsSelector = 'fids';

export const FidsActionsTypes = {
    GET_ALL: '[FIDS] GET -- ALL',
    GET_ONE: '[FIDS] GET -- ONE',
    CREATE_ONE: '[FIDS] CREATE -- ONE',
    UPDATE_ONE: '[FIDS] UPDATE -- ONE',
    DELETE_ONE: '[FIDS] DELETE -- ONE',
};

export const fidsGetAllCtx = createZtoAsyncExhaustCtx(`${fidsSelector}GetAll`, FidsActionsTypes.GET_ALL)<[], Fid[]>();
export const fidsGetOneCtx = createZtoAsyncExhaustCtx(`${fidsSelector}GetOne`, FidsActionsTypes.GET_ONE)<[string], Fid>();
export const fidsCreateOneCtx = createZtoAsyncExhaustCtx(`${fidsSelector}CreateOne`, FidsActionsTypes.CREATE_ONE)<[Partial<Fid>], Fid>();
export const fidsUpdateOneCtx = createZtoAsyncExhaustCtx(`${fidsSelector}UpdateOne`, FidsActionsTypes.UPDATE_ONE)<[string,Partial<Fid>], Fid>();
export const fidsDeleteOneCtx = createZtoAsyncExhaustCtx(`${fidsSelector}DeleteOne`, FidsActionsTypes.DELETE_ONE)<[string], Fid>();

export interface FidsState {
    loading: boolean;
    fetched: boolean;
    fids: EntityState<Fid>;
    error: { name: string, message: string };
    getAllCache: typeof fidsGetAllCtx['defaultState'];
    getOneCache: typeof fidsGetOneCtx['defaultState'];
    createOneCache: typeof fidsCreateOneCtx['defaultState'];
    updateOneCache: typeof fidsUpdateOneCtx['defaultState'];
    deleteOneCache: typeof fidsDeleteOneCtx['defaultState'];
}

const fidsAdapter = createEntityAdapter<Fid>();

export const initialFidsState: FidsState = {
    loading: false,
    fetched: false,
    fids: fidsAdapter.getInitialState(),
    error: undefined,
    getAllCache: fidsGetAllCtx.defaultState,
    getOneCache: fidsGetOneCtx.defaultState,
    createOneCache: fidsCreateOneCtx.defaultState,
    updateOneCache: fidsUpdateOneCtx.defaultState,
    deleteOneCache: fidsDeleteOneCtx.defaultState,
}

export type FidsActions = typeof fidsGetAllCtx['Actions'][keyof typeof fidsGetAllCtx['Actions']]['prototype']
    | typeof fidsGetOneCtx['Actions'][keyof typeof fidsGetOneCtx['Actions']]['prototype']
    | typeof fidsCreateOneCtx['Actions'][keyof typeof fidsCreateOneCtx['Actions']]['prototype']
    | typeof fidsUpdateOneCtx['Actions'][keyof typeof fidsUpdateOneCtx['Actions']]['prototype']
    | typeof fidsDeleteOneCtx['Actions'][keyof typeof fidsDeleteOneCtx['Actions']]['prototype'];

export function fidsReducer(state: FidsState = initialFidsState, action: FidsActions): FidsState {
    const isLoading = (st: FidsState) => st.getAllCache.loading || st.getOneCache.loading || st.createOneCache.loading || st.updateOneCache.loading || st.deleteOneCache.loading;
    const isError = (st: FidsState) => st.getAllCache.error || st.getOneCache.error || st.createOneCache.error || st.updateOneCache.error || st.deleteOneCache.error;
    switch (action.type) {

        case fidsGetAllCtx.Types.REQUEST: {
            const getAllCache = fidsGetAllCtx.reducer(state.getAllCache, action as any);
            const newState = { ...state, getAllCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case fidsGetAllCtx.Types.CANCEL: {
            const getAllCache = fidsGetAllCtx.reducer(state.getAllCache, action as any);
            const newState = { ...state, getAllCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case fidsGetAllCtx.Types.RESOLVE: {
            const getAllCache = fidsGetAllCtx.reducer(state.getAllCache, action as any);
            const newState = { ...state, getAllCache };
            return {
                ...newState,
                loading: isLoading(newState),
                fetched: true,
                fids: fidsAdapter.addAll(action.payload, { ...state.fids })
            };
        }
        case fidsGetAllCtx.Types.REJECT: {
            const getAllCache = fidsGetAllCtx.reducer(state.getAllCache, action as any);
            const newState = { ...state, getAllCache };
            return {
                ...newState,
                loading: isLoading(newState),
                error: action.payload
            };
        }
        case fidsGetAllCtx.Types.RESET: {
            const getAllCache = fidsGetAllCtx.reducer(state.getAllCache, action as any);
            const newState = { ...state, getAllCache };
            return {
                ...newState,
                loading: isLoading(newState),
                fids: fidsAdapter.removeAll({ ...state.fids }),
                fetched: false
            };
        }
        case fidsGetAllCtx.Types.CLEAR_ERRORS: {
            const getAllCache = fidsGetAllCtx.reducer(state.getAllCache, action as any);
            const newState = { ...state, getAllCache };
            return {
                ...newState,
                loading: isLoading(newState),
                error: isError(newState)
            };
        }


        case fidsGetOneCtx.Types.REQUEST: {
            const getOneCache = fidsGetOneCtx.reducer(state.getOneCache, action as any);
            const newState = { ...state, getOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case fidsGetOneCtx.Types.CANCEL: {
            const getOneCache = fidsGetOneCtx.reducer(state.getOneCache, action as any);
            const newState = { ...state, getOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case fidsGetOneCtx.Types.RESOLVE: {
            const getOneCache = fidsGetOneCtx.reducer(state.getOneCache, action as any);
            const newState = { ...state, getOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
                fids: fidsAdapter.upsertOne(action.payload, { ...state.fids })
            };
        }
        case fidsGetOneCtx.Types.REJECT: {
            const getOneCache = fidsGetOneCtx.reducer(state.getOneCache, action as any);
            const newState = { ...state, getOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
                error: action.payload
            };
        }
        case fidsGetOneCtx.Types.RESET: {
            const getOneCache = fidsGetOneCtx.reducer(state.getOneCache, action as any);
            const newState = { ...state, getOneCache };
            return {
                ...newState,
                loading: isLoading(newState)
            };
        }
        case fidsGetOneCtx.Types.CLEAR_ERRORS: {
            const getOneCache = fidsGetOneCtx.reducer(state.getOneCache, action as any);
            const newState = { ...state, getOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
                error: isError(newState)
            };
        }


        case fidsCreateOneCtx.Types.REQUEST: {
            const createOneCache = fidsCreateOneCtx.reducer(state.createOneCache, action as any);
            const newState = { ...state, createOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case fidsCreateOneCtx.Types.CANCEL: {
            const createOneCache = fidsCreateOneCtx.reducer(state.createOneCache, action as any);
            const newState = { ...state, createOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case fidsCreateOneCtx.Types.RESOLVE: {
            const createOneCache = fidsCreateOneCtx.reducer(state.createOneCache, action as any);
            const newState = { ...state, createOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
                fids: fidsAdapter.upsertOne(action.payload, { ...state.fids })
            };
        }
        case fidsCreateOneCtx.Types.REJECT: {
            const createOneCache = fidsCreateOneCtx.reducer(state.createOneCache, action as any);
            const newState = { ...state, createOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
                error: action.payload
            };
        }
        case fidsCreateOneCtx.Types.RESET: {
            const createOneCache = fidsCreateOneCtx.reducer(state.createOneCache, action as any);
            const newState = { ...state, createOneCache };
            return {
                ...newState,
                loading: isLoading(newState)
            };
        }
        case fidsCreateOneCtx.Types.CLEAR_ERRORS: {
            const createOneCache = fidsCreateOneCtx.reducer(state.createOneCache, action as any);
            const newState = { ...state, createOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
                error: isError(newState)
            };
        }


        case fidsUpdateOneCtx.Types.REQUEST: {
            const updateOneCache = fidsUpdateOneCtx.reducer(state.updateOneCache, action as any);
            const newState = { ...state, updateOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case fidsUpdateOneCtx.Types.CANCEL: {
            const updateOneCache = fidsUpdateOneCtx.reducer(state.updateOneCache, action as any);
            const newState = { ...state, updateOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case fidsUpdateOneCtx.Types.RESOLVE: {
            const updateOneCache = fidsUpdateOneCtx.reducer(state.updateOneCache, action as any);
            const newState = { ...state, updateOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
                fids: fidsAdapter.upsertOne(action.payload, { ...state.fids })
            };
        }
        case fidsUpdateOneCtx.Types.REJECT: {
            const updateOneCache = fidsUpdateOneCtx.reducer(state.updateOneCache, action as any);
            const newState = { ...state, updateOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
                error: action.payload
            };
        }
        case fidsUpdateOneCtx.Types.RESET: {
            const updateOneCache = fidsUpdateOneCtx.reducer(state.updateOneCache, action as any);
            const newState = { ...state, updateOneCache };
            return {
                ...newState,
                loading: isLoading(newState)
            };
        }
        case fidsUpdateOneCtx.Types.CLEAR_ERRORS: {
            const updateOneCache = fidsUpdateOneCtx.reducer(state.updateOneCache, action as any);
            const newState = { ...state, updateOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
                error: isError(newState)
            };
        }


        case fidsDeleteOneCtx.Types.REQUEST: {
            const deleteOneCache = fidsDeleteOneCtx.reducer(state.deleteOneCache, action as any);
            const newState = { ...state, deleteOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case fidsDeleteOneCtx.Types.CANCEL: {
            const deleteOneCache = fidsDeleteOneCtx.reducer(state.deleteOneCache, action as any);
            const newState = { ...state, deleteOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case fidsDeleteOneCtx.Types.RESOLVE: {
            const deleteOneCache = fidsDeleteOneCtx.reducer(state.deleteOneCache, action as any);
            const newState = { ...state, deleteOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
                fids: fidsAdapter.removeOne(action.payload.id, { ...state.fids })
            };
        }
        case fidsDeleteOneCtx.Types.REJECT: {
            const deleteOneCache = fidsDeleteOneCtx.reducer(state.deleteOneCache, action as any);
            const newState = { ...state, deleteOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
                error: action.payload
            };
        }
        case fidsDeleteOneCtx.Types.RESET: {
            const deleteOneCache = fidsDeleteOneCtx.reducer(state.deleteOneCache, action as any);
            const newState = { ...state, deleteOneCache };
            return {
                ...newState,
                loading: isLoading(newState)
            };
        }
        case fidsDeleteOneCtx.Types.CLEAR_ERRORS: {
            const deleteOneCache = fidsDeleteOneCtx.reducer(state.deleteOneCache, action as any);
            const newState = { ...state, deleteOneCache };
            return {
                ...newState,
                loading: isLoading(newState),
                error: isError(newState)
            };
        }
    
        default:
            return state;
    }
}

export const fidsSelectStateFn = (state: any) => state[fidsSelector] as FidsState;

export const fidsSelectorFns = {
    state: fidsSelectStateFn,
    loading: createSelector(fidsSelectStateFn, state => state.loading),
    fetched: createSelector(fidsSelectStateFn, state => state.fetched),
    fids: createSelector(fidsSelectStateFn, state => state.fids),
    fidsEntities: createSelector(fidsSelectStateFn, state => state.fids.entities),
    fidsIds: createSelector(fidsSelectStateFn, state => state.fids.ids),
    error: createSelector(fidsSelectStateFn, state => state.error),
    getAllCache: createSelector(fidsSelectStateFn, state => state.getAllCache),
    getOneCache: createSelector(fidsSelectStateFn, state => state.getOneCache),
    createOneCache: createSelector(fidsSelectStateFn, state => state.createOneCache),
    updateOneCache: createSelector(fidsSelectStateFn, state => state.updateOneCache),
    deleteOneCache: createSelector(fidsSelectStateFn, state => state.deleteOneCache),
};

export const createFidsSelectors = (store: Store<any>) => ({
    state: store.pipe(map(fidsSelectorFns.state)),
    loading: store.pipe(map(fidsSelectorFns.loading)),
    fetched: store.pipe(map(fidsSelectorFns.fetched)),
    fids: store.pipe(map(fidsSelectorFns.fids)),
    fidsEntities: store.pipe(map(fidsSelectorFns.fidsEntities)),
    fidsIds: store.pipe(map(fidsSelectorFns.fidsIds)),
    error: store.pipe(map(fidsSelectorFns.error)),
    getAllCache: store.pipe(map(fidsSelectorFns.getAllCache)),
    getOneCache: store.pipe(map(fidsSelectorFns.getOneCache)),
    createOneCache: store.pipe(map(fidsSelectorFns.createOneCache)),
    updateOneCache: store.pipe(map(fidsSelectorFns.updateOneCache)),
    deleteOneCache: store.pipe(map(fidsSelectorFns.deleteOneCache)),
});
