import { ZtoAction, createZtoAsyncExhaustCtx } from 'src/app/libs/zto-redux/zto-redux';
import { createSelector, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

export interface Credentials {
    email: string;
    password: string;
}

export interface User {
    id: string;
    email: string;
    password: string;
}

export const authSelector = 'auth';

export const AuthActionsTypes = {
    SET_CREDENTIALS: '[AUTH] CREDENTIALS -- SET',
    AUTO_SIGNIN: '[AUTH] AUTO SIGNIN',
    SIGNIN: '[AUTH] SIGNIN',
    SIGNUP: '[AUTH] SIGNUP',
    SIGNOUT: '[AUTH] SIGNOUT',
};

export const authSigninCtx = createZtoAsyncExhaustCtx(`${authSelector}Signin`, AuthActionsTypes.SIGNIN)<[Credentials], { token: string, user: User }>();
export const authAutoSigninCtx = createZtoAsyncExhaustCtx(`${authSelector}AutoSignin`, AuthActionsTypes.AUTO_SIGNIN)<[], { token: string, user: User }>();
export const authSignupCtx = createZtoAsyncExhaustCtx(`${authSelector}Signup`, AuthActionsTypes.SIGNUP)<[Credentials], User>();
export const authSignoutCtx = createZtoAsyncExhaustCtx(`${authSelector}Signout`, AuthActionsTypes.SIGNOUT)<[], {}>();

export interface AuthState {
    loading: boolean;
    authenticated: boolean;
    token?: string;
    credentials?: Credentials;
    user?: User;
    autoSigninCache: typeof authAutoSigninCtx['defaultState'];
    signinCache: typeof authSigninCtx['defaultState'];
    signupCache: typeof authSignupCtx['defaultState'];
    signoutCache: typeof authSignoutCtx['defaultState'];
}

export const initialAuthState: AuthState = {
    loading: false,
    authenticated: false,
    autoSigninCache: authAutoSigninCtx.defaultState,
    signinCache: authSigninCtx.defaultState,
    signupCache: authSignupCtx.defaultState,
    signoutCache: authSignoutCtx.defaultState,
}


export class AuthSetCredentials extends ZtoAction<typeof AuthActionsTypes.SET_CREDENTIALS, Credentials> {
    constructor(
        payload: Credentials
    ) {
        super(AuthActionsTypes.SET_CREDENTIALS, payload);
    }
}

export type AuthActions = AuthSetCredentials
    | typeof authAutoSigninCtx['Actions'][keyof typeof authAutoSigninCtx['Actions']]['prototype']
    | typeof authSigninCtx['Actions'][keyof typeof authSigninCtx['Actions']]['prototype']
    | typeof authSignupCtx['Actions'][keyof typeof authSignupCtx['Actions']]['prototype']
    | typeof authSignoutCtx['Actions'][keyof typeof authSignoutCtx['Actions']]['prototype'];

export function authReducer(state: AuthState = initialAuthState, action: AuthActions): AuthState {
    const isLoading = (st: AuthState) => st.signinCache.loading || st.autoSigninCache.loading || st.signupCache.loading || st.signoutCache.loading;
    switch (action.type) {
        case AuthActionsTypes.SET_CREDENTIALS:
            return {
                ...state,
                credentials: (action as AuthSetCredentials).payload
            };

        case authAutoSigninCtx.Types.REQUEST: {
            const autoSigninCache = authAutoSigninCtx.reducer(state.autoSigninCache, action as any);
            const newState = { ...state, autoSigninCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case authAutoSigninCtx.Types.CANCEL: {
            const autoSigninCache = authAutoSigninCtx.reducer(state.autoSigninCache, action as any);
            const newState = { ...state, autoSigninCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case authAutoSigninCtx.Types.RESOLVE: {
            const autoSigninCache = authAutoSigninCtx.reducer(state.autoSigninCache, action as any);
            const newState = { ...state, autoSigninCache };
            return {
                ...newState,
                loading: isLoading(newState),
                token: autoSigninCache.result.token,
                user: autoSigninCache.result.user,
                authenticated: true
            };
        }
        case authAutoSigninCtx.Types.REJECT: {
            const autoSigninCache = authAutoSigninCtx.reducer(state.autoSigninCache, action as any);
            const newState = { ...state, autoSigninCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case authAutoSigninCtx.Types.RESET: {
            const autoSigninCache = authAutoSigninCtx.reducer(state.autoSigninCache, action as any);
            const newState = { ...state, autoSigninCache };
            return {
                ...newState,
                loading: isLoading(newState),
                user: undefined,
                token: undefined,
                authenticated: false
            };
        }
        case authAutoSigninCtx.Types.CLEAR_ERRORS: {
            const autoSigninCache = authAutoSigninCtx.reducer(state.autoSigninCache, action as any);
            const newState = { ...state, autoSigninCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }

        case authSigninCtx.Types.REQUEST: {
            const signinCache = authSigninCtx.reducer(state.signinCache, action as any);
            const newState = { ...state, signinCache };
            return {
                ...newState,
                loading: isLoading(newState),
                credentials: action.payload[0]
            };
        }
        case authSigninCtx.Types.CANCEL: {
            const signinCache = authSigninCtx.reducer(state.signinCache, action as any);
            const newState = { ...state, signinCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case authSigninCtx.Types.RESOLVE: {
            const signinCache = authSigninCtx.reducer(state.signinCache, action as any);
            const newState = { ...state, signinCache };
            return {
                ...newState,
                loading: isLoading(newState),
                token: signinCache.result.token,
                user: signinCache.result.user,
                authenticated: true
            };
        }
        case authSigninCtx.Types.REJECT: {
            const signinCache = authSigninCtx.reducer(state.signinCache, action as any);
            const newState = { ...state, signinCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case authSigninCtx.Types.RESET: {
            const signinCache = authSigninCtx.reducer(state.signinCache, action as any);
            const newState = { ...state, signinCache };
            return {
                ...newState,
                loading: isLoading(newState),
                user: undefined,
                token: undefined,
                authenticated: false
            };
        }
        case authSigninCtx.Types.CLEAR_ERRORS: {
            const signinCache = authSigninCtx.reducer(state.signinCache, action as any);
            const newState = { ...state, signinCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }

        case authSignupCtx.Types.REQUEST: {
            const signupCache = authSignupCtx.reducer(state.signupCache, action as any);
            const newState = { ...state, signupCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case authSignupCtx.Types.CANCEL: {
            const signupCache = authSignupCtx.reducer(state.signupCache, action as any);
            const newState = { ...state, signupCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case authSignupCtx.Types.RESOLVE: {
            const signupCache = authSignupCtx.reducer(state.signupCache, action as any);
            const newState = { ...state, signupCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case authSignupCtx.Types.REJECT: {
            const signupCache = authSignupCtx.reducer(state.signupCache, action as any);
            const newState = { ...state, signupCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case authSignupCtx.Types.RESET: {
            const signupCache = authSignupCtx.reducer(state.signupCache, action as any);
            const newState = { ...state, signupCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case authSignupCtx.Types.CLEAR_ERRORS: {
            const signupCache = authSignupCtx.reducer(state.signupCache, action as any);
            const newState = { ...state, signupCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }

        case authSignoutCtx.Types.REQUEST: {
            const signoutCache = authSignoutCtx.reducer(state.signoutCache, action as any);
            const newState = { ...state, signoutCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case authSignoutCtx.Types.CANCEL: {
            const signoutCache = authSignoutCtx.reducer(state.signoutCache, action as any);
            const newState = { ...state, signoutCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case authSignoutCtx.Types.RESOLVE: {
            const signoutCache = authSignoutCtx.reducer(state.signoutCache, action as any);
            const newState = { ...state, signoutCache };
            return {
                ...newState,
                loading: isLoading(newState),
                authenticated: false,
                user: undefined,
                token: undefined
            };
        }
        case authSignoutCtx.Types.REJECT: {
            const signoutCache = authSignoutCtx.reducer(state.signoutCache, action as any);
            const newState = { ...state, signoutCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case authSignoutCtx.Types.RESET: {
            const signoutCache = authSignoutCtx.reducer(state.signoutCache, action as any);
            const newState = { ...state, signoutCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
        case authSignoutCtx.Types.CLEAR_ERRORS: {
            const signoutCache = authSignoutCtx.reducer(state.signoutCache, action as any);
            const newState = { ...state, signoutCache };
            return {
                ...newState,
                loading: isLoading(newState),
            };
        }
    
        default:
            return state;
    }
}

export const authSelectStateFn = (state: any) => state[authSelector] as AuthState;

export const authSelectorFns = {
    state: authSelectStateFn,
    loading: createSelector(authSelectStateFn, state => state.loading),
    authenticated: createSelector(authSelectStateFn, state => state.authenticated),
    credentials: createSelector(authSelectStateFn, state => state.credentials),
    token: createSelector(authSelectStateFn, state => state.token),
    user: createSelector(authSelectStateFn, state => state.user),
    autoSigninCache: createSelector(authSelectStateFn, state => state.autoSigninCache),
    signinCache: createSelector(authSelectStateFn, state => state.signinCache),
    signupCache: createSelector(authSelectStateFn, state => state.signupCache),
    signoutCache: createSelector(authSelectStateFn, state => state.signoutCache),
};

export const createAuthSelectors = (store: Store<any>) => ({
    state: store.pipe(map(authSelectorFns.state)),
    loading: store.pipe(map(authSelectorFns.loading)),
    authenticated: store.pipe(map(authSelectorFns.authenticated)),
    credentials: store.pipe(map(authSelectorFns.credentials)),
    token: store.pipe(map(authSelectorFns.token)),
    user: store.pipe(map(authSelectorFns.user)),
    autoSigninCache: store.pipe(map(authSelectorFns.autoSigninCache)),
    signinCache: store.pipe(map(authSelectorFns.signinCache)),
    signupCache: store.pipe(map(authSelectorFns.signupCache)),
    signoutCache: store.pipe(map(authSelectorFns.signoutCache)),
});
