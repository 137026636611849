import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFacade } from 'src/app/redux/app/facade';
import { MatSidenav } from '@angular/material/sidenav';
import { Link } from 'src/app/redux/app/state';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { DialogsService } from 'src/app/services/dialogs/dialogs.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  links$: Observable<any[]> = this.app.links$;
  loading$: Observable<boolean> = this.app.isLoading$;
  error$: Observable<{ name: string, message: string }> = this.app.error$;

  snavOpen: boolean = false;

  constructor(
    private app: AppFacade,
    private router: Router,
    private nav: NavigationService,
    private dialog: DialogsService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }

  navigate(link: Link) {
    if (link.params && link.params.view === 'signout') {
      const signout = this.translate.instant('dialogs.confirmation.signout');
      this.dialog.openConfirmationDialog({ data: {
        question: signout.question,
        positiveAnswer: {
          text: signout.positive,
          handler: () => {
            localStorage.removeItem('credentials');
            this.snavOpen = false;
            this.router.navigate(['/sign'], { queryParams: { view: 'signout' } });
          }
        },
        negativeAnswer: {
          text: signout.negative,
          handler: () => {
            this.snavOpen = false;
          }
        }
      } });
    } else {
      this.snavOpen = false;
      this.router.navigate([link.link], { queryParams: link.params });
    }
    this.nav.backBtn.next({ hidden: true })
  }

  back({ link, params }: { link: string, params?: any }) {
    this.navigate({ link, params, translateKey: undefined });
    this.nav.backBtn.next({ hidden: true });
  }

  gotoDashboard() {
    this.navigate({ link: '/dashboard', translateKey: undefined });
  }

  gotoInfos() {
    this.navigate({ link: '/infos', translateKey: undefined });
  }

  gotoNotifs() {
    this.navigate({ link: '/notifications', translateKey: undefined });
  }

}
