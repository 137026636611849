import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FidsService } from 'src/app/services/fids/fids.service';
import { take, switchMap, map, distinctUntilKeyChanged, tap, concatMap, withLatestFrom, filter } from 'rxjs/operators';
import { timer } from 'rxjs';
import { createFidsSelectors, fidsGetAllCtx, fidsGetOneCtx, fidsCreateOneCtx, fidsUpdateOneCtx, fidsDeleteOneCtx, Fid } from './state';
import { AuthFacade } from '../auth/facade';

@Injectable()
export class FidsEffects {

    fidsSelect = createFidsSelectors(this.store);

    @Effect() getAll$ = fidsGetAllCtx.effect({ serviceFn: () => this.fids.getAll() }, this.actions, this.store);
    @Effect() getOne$ = fidsGetOneCtx.effect({ serviceFn: (id: string) => this.fids.getOne(id) }, this.actions, this.store);
    @Effect() createOne$ = fidsCreateOneCtx.effect({ serviceFn: (fid: Partial<Fid>) => this.fids.createOne(fid) }, this.actions, this.store);
    @Effect() updateOne$ = fidsUpdateOneCtx.effect({ serviceFn: (id: string, changes: Partial<Fid>) => this.fids.updateOne(id, changes) }, this.actions, this.store);
    @Effect() deleteOne$ = fidsDeleteOneCtx.effect({ serviceFn: (id: string) => this.fids.deleteOne(id) }, this.actions, this.store);

    @Effect() startup$ = this.auth.select.authenticated.pipe(
        filter(authenticated => authenticated),
        take(1),
        map(() => new fidsGetAllCtx.Actions.Request([]))
    );

    constructor(
        private actions: Actions,
        private store: Store<any>,
        private fids: FidsService,
        private auth: AuthFacade
    ) {}
}

