import { appSelector, appReducer } from './app/state';
import { AppEffects } from './app/effects';
import { AppFacade } from './app/facade';
import { authSelector, authReducer } from './auth/state';
import { AuthEffects } from './auth/effects';
import { AuthFacade } from './auth/facade';
import { fidsSelector, fidsReducer } from './fids/state';
import { FidsEffects } from './fids/effects';
import { FidsFacade } from './fids/facade';
import { notificationsReducer } from './notifications/state';
import { NotificationsEffects } from './notifications/effects';
import { NotificationsFacade } from './notifications/facade';

export const reducers = {
    'app': appReducer,
    'auth': authReducer,
    'fids': fidsReducer,
    'notifications': notificationsReducer
};
export const metaReducers = {};
export const effects = [
    AppEffects,
    AuthEffects,
    FidsEffects,
    NotificationsEffects
];
export const facades = [
    AppFacade,
    AuthFacade,
    FidsFacade,
    NotificationsFacade
];
export const devToolsConfig = { maxAge: 100 };
