import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  backBtn: BehaviorSubject<{ hidden: boolean, link?: string, params?: any }> = new BehaviorSubject({ hidden: true });

  constructor() { }
}
