import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/services/auth/auth.service';
import { authSignupCtx, authSigninCtx, authSignoutCtx, authAutoSigninCtx, Credentials, createAuthSelectors, AuthSetCredentials } from './state';
import { take, switchMap, map, distinctUntilKeyChanged, tap, concatMap, withLatestFrom, filter } from 'rxjs/operators';
import { timer } from 'rxjs';

@Injectable()
export class AuthEffects {

    authSelect = createAuthSelectors(this.store);

    @Effect()
    signup$ = authSignupCtx.effect({ serviceFn: (credentials: Credentials) => this.auth.signup(credentials) }, this.actions, this.store);

    @Effect()
    signin$ = authSigninCtx.effect({ serviceFn: (credentials: Credentials) => this.auth.signin(credentials) }, this.actions, this.store);

    @Effect()
    signout$ = authSignoutCtx.effect({ serviceFn: () => this.auth.signout() }, this.actions, this.store);

    @Effect()
    autoSignin$ = authAutoSigninCtx.effect({
        serviceFn: () => this.authSelect.credentials.pipe(
            filter(credentials => !!credentials),
            take(1),
            switchMap(credentials => this.auth.signin(credentials))
        )
    }, this.actions, this.store);

    @Effect({ dispatch: false })
    persist$ = timer(500).pipe(
        switchMap(() => this.authSelect.state.pipe(
            distinctUntilKeyChanged('authenticated'),
            tap(auth => {
                if (auth.authenticated && auth.credentials) {
                    localStorage.setItem('credentials', JSON.stringify(auth.credentials));
                    localStorage.setItem('token', auth.token);
                    localStorage.setItem('user', JSON.stringify(auth.user));
                    localStorage.setItem('authenticated', JSON.stringify(auth.authenticated));
                } else {
                }
            })
        ))
    );

    @Effect()
    signinAfterSignup$ = this.actions.pipe(
        ofType(authSignupCtx.Types.RESOLVE),
        withLatestFrom(this.authSelect.signupCache.pipe(
            filter(cache => cache && cache.params && cache.params.length > 0),
            map(cache => cache.params),
            take(1)
        )),
        concatMap(([, [credentials]]) => [
            new AuthSetCredentials(credentials),
            new authAutoSigninCtx.Actions.Request([]),
        ])
    );

    @Effect()
    startup$ = timer(0).pipe(
        concatMap(() => {
            const credentials = localStorage.getItem('credentials');
            const token = localStorage.getItem('token');
            const user = localStorage.getItem('user');
            if (token && user) {
                return [new authAutoSigninCtx.Actions.Resolve({ token, user: user && JSON.parse(user) })];
            } else if (credentials) {
                return [
                    new AuthSetCredentials(JSON.parse(credentials)),
                    new authAutoSigninCtx.Actions.Request([]),
                ];
            } else {
                return [];
            }
        })
    );

    constructor(
        private actions: Actions,
        private store: Store<any>,
        private auth: AuthService
    ) {}
}

