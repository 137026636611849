import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private http: HttpClient
  ) { }

  send(visitId: string) {
    const body = { visitId, type: 'REMINDER', message: 'sample notification' };
    return this.http.post<any>(`${environment.baseApi}/v1/notification/notify`, body);
  }

  getAll() {
    return of([]).pipe(
        map(notifications => notifications.filter(notification => !!notification.contact).map(notification => ({ ...notification, id: notification.pragmatistid })))
    )
}
}
