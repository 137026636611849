import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { fidsGetAllCtx, createFidsSelectors, Fid, fidsGetOneCtx, fidsCreateOneCtx, fidsUpdateOneCtx, fidsDeleteOneCtx } from './state';

@Injectable()
export class FidsFacade {

    select = createFidsSelectors(this.store);

    constructor(
        private store: Store<any>
    ) {}

    getAll() {
        this.store.dispatch(new fidsGetAllCtx.Actions.Request([]));
    }

    getOne(id: string) {
        this.store.dispatch(new fidsGetOneCtx.Actions.Request([id]));
    }

    createOne(fid: Partial<Fid>) {
        this.store.dispatch(new fidsCreateOneCtx.Actions.Request([fid]));
    }

    updateOne(id: string, changes: Partial<Fid>) {
        this.store.dispatch(new fidsUpdateOneCtx.Actions.Request([id, changes]));
    }

    deleteOne(id: string) {
        this.store.dispatch(new fidsDeleteOneCtx.Actions.Request([id]));
    }
}