import { Injectable } from "@angular/core";
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { take, map, filter } from 'rxjs/operators';
import { createNotificationsSelectors, notificationsGetAllCtx, notificationsSendCtx } from './state';
import { AuthFacade } from '../auth/facade';

@Injectable()
export class NotificationsEffects {

    @Effect() getAll$ = notificationsGetAllCtx.effect({ serviceFn: () => this.notifications.getAll() }, this.actions, this.store);
    @Effect() send$ = notificationsSendCtx.effect({ serviceFn: (visitId: string) => this.notifications.send(visitId) }, this.actions, this.store);

    @Effect() startup$ = this.auth.select.authenticated.pipe(
        filter(authenticated => authenticated),
        take(1),
        map(() => new notificationsGetAllCtx.Actions.Request([]))
    );

    constructor(
        private actions: Actions,
        private store: Store<any>,
        private notifications: NotificationsService,
        private auth: AuthFacade
    ) {}
}

