import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { Credentials, authSignupCtx, authAutoSigninCtx, authSigninCtx, authSignoutCtx, AuthSetCredentials, createAuthSelectors } from './state';

@Injectable()
export class AuthFacade {

    select = createAuthSelectors(this.store);

    constructor(
        private store: Store<any>
    ) {}

    setCredentials(credentials: Credentials) {
        this.store.dispatch(new AuthSetCredentials(credentials));
    }

    signup(credentials: Credentials) {
        this.store.dispatch(new authSignupCtx.Actions.Request([credentials]));
    }

    autoSignin() {
        this.store.dispatch(new authAutoSigninCtx.Actions.Request([]));
    }

    signin(credentials: Credentials) {
        this.store.dispatch(new authSigninCtx.Actions.Request([credentials]));
    }

    signout() {
        this.store.dispatch(new authSignoutCtx.Actions.Request([]));
    }
}